<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#00000"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex px-2 pt-2 xs12 sm12 md12 lg12 xl12 text-center>
        <!-- <v-card> -->
        <v-card-title class="elevation-1">
          Volunteer
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start v-if="user.length>0">
          <template v-for="(item, i) in user">
            <v-flex xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
              <v-layout wrap py-2>
                <v-flex xs12 sm12 md12 lg12>
                  <v-layout wrap justify-start>
                    <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
                      <v-layout wrap v-if="item.cv">
                        <v-flex xs12>
                          <span style="font-weight: bold; font-size: 20px"
                            >CV</span
                          >
                        </v-flex>
                        <v-flex xs12 v-if="item.cv.split('.').pop() == 'pdf'">
                          <a
                            target="_blank"
                            style="text-decoration: none"
                            :href="'https://api.speleoindia.org.in/u/' + item.cv"
                            >Click to Dowload</a
                          >
                        </v-flex>
                        <v-flex xs12 v-else>
                          <div v-viewer style="display: flex">
                            <img
                              :src="mediaURL + item.cv"
                              aspect-ratio="1"
                              contain
                              style="object-fit: cover; cursor: pointer"
                              height="100px"
                            />
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 sm7 md7 lg8 xl8>
                      <v-layout wrap justify-center fill-height>
                        <v-flex xs12>
                          <v-layout wrap justify-start>
                            <v-flex lg12 text-left>
                              <span style="font-size: 16px; color: #000000">
                                {{ item.salutation }}.
                                {{ item.name.firstName }}
                                {{ item.name.middleName }}
                                {{ item.name.surname }}
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 align-self-end>
                          <v-layout wrap justify-start>
                            <v-flex xs6 sm2 md2 lg2 xl2>
                              <v-layout wrap>
                                <v-flex text-left xs12>
                                  <span style="color: #8d8d8d; font-size: 14px">
                                    Age
                                  </span>
                                </v-flex>
                                <v-flex text-left xs12>
                                  <span style="font-size: 18px">
                                    {{ item.age }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm3 md3 lg3 xl3>
                              <v-layout wrap>
                                <v-flex text-left xs12>
                                  <span style="color: #8d8d8d; font-size: 14px">
                                    Gender
                                  </span>
                                </v-flex>
                                <v-flex text-left xs12>
                                  <span style="font-size: 18px">
                                    {{ item.gender }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm3 md3 lg3 xl3>
                              <v-layout wrap>
                                <v-flex text-left xs12>
                                  <span style="color: #8d8d8d; font-size: 14px">
                                    Phone
                                  </span>
                                </v-flex>
                                <v-flex text-left xs12>
                                  <span style="font-size: 18px">
                                    {{ item.phone }}
                                  </span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm4 md4 lg4 xl4>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span style="color: #8d8d8d; font-size: 14px"
                                    >Email</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span style="color: black; font-size: 18px">{{
                                    item.email
                                  }}</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                          <v-layout justify-start fill-height wrap>
                            <v-flex xs6 sm4 md4 lg4 xl4>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span style="color: #8d8d8d; font-size: 14px"
                                    >About Us</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span style="color: black; font-size: 18px">{{
                                    item.hearAboutUs
                                  }}</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                            <v-flex xs6 sm8 md8 lg8 xl8>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span style="color: #8d8d8d; font-size: 14px"
                                    >Work Interest</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap>
                                <v-flex text-left lg12>
                                  <span style="color: black; font-size: 18px">{{
                                    item.workInterest
                                  }}</span>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 py-2 align-self-center>
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                      <v-flex pt-5 px-5 xs2 sm2 md2 justify-center>
                  <v-dialog v-model="item.delete" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="mr-2"
                        outlined
                        block
                        v-on="on"
                        v-bind="attrs"
                        color="red"
                      >
                        Delete
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title
                        >Are you sure you want to delete this
                        Volunteer?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="item.delete = false"
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="deleteItem(item)"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-flex>
                    <!-- <v-flex text-left xs1 text-center hidden-md-and-down>
                        <v-divider vertical></v-divider>
                      </v-flex> -->
                  </v-layout>
                  <!-- <v-flex xs8 align-self-center>
                        <v-divider></v-divider>
                      </v-flex> -->
                  <v-flex xs12 pa-2>
                    <v-layout wrap>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Building No
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.buildingNo }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Land Mark
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.landmark }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Street
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.street }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              City
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.city }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              District
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.district }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              State
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.state }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Country
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.country }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs6 sm3 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Pincode
                            </span>
                          </v-flex>
                          <v-flex text-left xs12>
                            <span style="font-size: 18px">
                              {{ item.address.pincode }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 xl11 :key="i" py-4>
              <v-divider></v-divider>
            </v-flex>
          </template>
        </v-layout>
        <v-layout wrap justify-center v-else pt-5>
          <v-flex xs12>
            <span style="font-size: 25px; color: #000000">
              Oops! No Data Found
            </span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center v-if="pages > 1">
          <v-flex xs12>
            <v-pagination
              v-model="currentPage"
              :length="pages"
              :total-visible="7"
              color="#FF0000"
            ></v-pagination>
          </v-flex>
        </v-layout>
        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      search: "",
      msg: "",
      page: 1,
      currentPage: 1,
      pages: 0,
      limit: 15,
      showsnackbar: false,
      ServerError: false,
      offer: "",
      editingitem: "",
      editdialog: false,
      user: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/volunteer/getlist",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/volunteer/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
